export default class QuantityButtons {
  constructor() {
    this.mainProductPlusButton = document.querySelector(
      ".entry-summary .qty-btn.btn-plus"
    );
    this.mainProductMinusButton = document.querySelector(
      ".entry-summary .qty-btn.btn-minus"
    );
    this.mainProductQuantityInput = document.querySelector(
      ".entry-summary input#quantity"
    );
    this.priceContainer = document.querySelector(".actual-price");
    if (this.priceContainer) {
      this.standardPrice = this.priceContainer.dataset.price;
    }

    this.accessoriesPlusButtons = document.querySelectorAll(
      ".single-accessory .qty-btn.btn-plus"
    );
    this.accessoriesMinusButtons = document.querySelectorAll(
      ".single-accessory .qty-btn.btn-minus"
    );

    if (
      this.mainProductPlusButton &&
      this.mainProductMinusButton &&
      this.mainProductQuantityInput
    ) {
      this.init();
    }
  }

  init() {
    this.mainProductHandler();
    this.accessoriesHandler();
  }

  mainProductHandler() {
    this.mainProductPlusButton.addEventListener("click", e => {
      e.preventDefault();
      this.changeQuantity("add");
    });
    this.mainProductMinusButton.addEventListener("click", e => {
      e.preventDefault();
      this.changeQuantity("subtract");
    });
    this.mainProductQuantityInput.addEventListener("focusout", () => {
      this.changeQuantity();
    });
  }

  changeQuantity(action) {
    if (action == "add") {
      this.mainProductQuantityInput.value++;
      this.calcActualPrice();
    } else if (action == "subtract") {
      if (this.mainProductQuantityInput.value > 1) {
        this.mainProductQuantityInput.value--;
        this.calcActualPrice();
      }
    } else {
      this.calcActualPrice();
    }
  }

  calcActualPrice() {
    this.priceContainer.innerText =
      this.standardPrice * this.mainProductQuantityInput.value;
  }

  accessoriesHandler() {
    this.accessoriesPlusButtons.forEach(btn => {
      btn.addEventListener("click", e => {
        e.preventDefault();
        this.accessoryQuantity(e, "add");
      });
    });

    this.accessoriesMinusButtons.forEach(btn => {
      btn.addEventListener("click", e => {
        e.preventDefault();
        this.accessoryQuantity(e, "subtract");
      });
    });
  }

  accessoryQuantity(e, action) {
    const accContainer = e.target.closest(".product-quantity-container");
    const input = accContainer.querySelector("input#quantity");
    if (action == "add") {
      input.value++;
    } else if (action == "subtract") {
      if (input.value > 1) {
        input.value--;
      }
    }
  }
}
