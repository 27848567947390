const mobileMenu = document.getElementsByClassName('menu-item-has-children');
import $ from "jquery";
console.log($('#site-navigation .menu-item-has-children'));
$('#site-navigation .menu-item-has-children').on('click', function(e) {
  var li = $(this);
  var target = $(e.target);

  var device_width = window.innerWidth > 0 ? window.innerWidth : screen.width;
  if (device_width < 1260 && target.is(li)) {
    e.preventDefault();
    var ul = li.children('ul');
    li.toggleClass('toggled');
    if (ul.attr('aria-expanded') == 'true') {
      ul.attr('aria-expanded', 'false');
    } else {
      ul.attr('aria-expanded', 'true');
    }
  }
});
