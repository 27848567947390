import { isMobile, selectTypeOfCustomer } from './helper';
import SiteHeader from './siteHeader';
import { toggleMenu, addSticky, closeMenu } from './navigation';
import FloatLabels from './forms/float-labels';
import QuantityButtons from './woocommerce/quantityButtons';
import AddToCart from './woocommerce/addToCart';
import SortableProducts from './sortableProducts';
import $ from "jquery";

window.addEventListener('load', () => {
  new SiteHeader();
  new FloatLabels();
  new QuantityButtons();

  const addToCartBtn = document.querySelector('.entry-summary .single_add_to_cart_button');
  if (addToCartBtn) {
    new AddToCart(addToCartBtn);
  }

  const customerSelection = document.querySelector('.type-of-customer-selection');
  if (customerSelection) {
    selectTypeOfCustomer();
  }

  const orderSelect = document.querySelector("select[name='products-order']");
  if (orderSelect) {
    new SortableProducts(orderSelect);
  }

  const additionalInfoLabel = document.querySelector('.woocommerce-product-attributes-item--dimensions');
  if (additionalInfoLabel) {
    additionalInfoLabel.querySelector('.woocommerce-product-attributes-item__label').innerText = 'Wymiary paczki';
  }

  const shippingMethodsContainer = document.querySelector('#shipping_method');
  if (shippingMethodsContainer) {
    if (shippingMethodsContainer.childElementCount == 1) {
      const billingCode = document.querySelector('#billing_postcode');
      checkInput(billingCode);
      handleBillingCodeChange(billingCode);
    }
  }

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};

function handleBillingCodeChange(container) {
  container.addEventListener('change', e => {
    const billingCodeBeginning = e.target.value.slice(0, 2);
    checkInput(container);
    if (billingCodeBeginning < 60 || billingCodeBeginning > 64) {
      openPopup();
    }
  });
}

function checkInput(billingCode) {
  const billingCodeBeginning = billingCode.value.slice(0, 2);
  const placeOrderBtn = document.querySelector('#place_order');
  if (billingCodeBeginning < 60 || billingCodeBeginning > 64) {
    placeOrderBtn.disabled = true;
  } else {
    placeOrderBtn.disabled = false;
  }
}

function openPopup() {
  const warningPopup = document.querySelector('.shipping-warning-popup');
  warningPopup.classList.add('open');
}
