import $ from "jquery";

export default class AddToCart {
  constructor(button) {
    this.addProductToCartBtn = button;
    this.addAccessoriesToCartBtns = document.querySelectorAll(
      ".single_add__accessory_to_cart_button"
    );

    this.init();
  }

  init() {
    this.addProductToCartBtn.addEventListener("click", e => {
      e.preventDefault();
      let form = this.addProductToCartBtn.closest("form.cart");
      let formData = new FormData(form);
      formData.append("action", "customAddToCart");
      this.addProductToCartBtn.classList.add("adding");

      $.ajax({
        method: "POST",
        url: wc_add_to_cart_params.ajax_url,
        data: formData,
        type: "POST",
        processData: false,
        contentType: false,
        success: response => {
          let event = new Event("wc_fragment_refresh");
          document.body.dispatchEvent(event);
          this.addProductToCartBtn.classList.remove("adding");
        }
      });
    });

    this.addAccessoriesToCartBtns.forEach(button => {
      button.addEventListener("click", e => {
        e.preventDefault();
        let form = button.closest("form.cart");
        let formData = new FormData(form);
        formData.append("action", "customAddToCart");
        button.classList.add("adding");

        $.ajax({
          method: "POST",
          url: wc_add_to_cart_params.ajax_url,
          data: formData,
          type: "POST",
          processData: false,
          contentType: false,
          success: response => {
            let event = new Event("wc_fragment_refresh");
            document.body.dispatchEvent(event);
            button.classList.remove("adding");
          }
        });
      });
    });
  }
}
