export const selectTypeOfCustomer = () => {
  const individual = document.querySelector('#type-of-customer-individual');
  const business = document.querySelector('#type-of-customer-business');
  const firstNameContainer = document.querySelector('#billing_first_name_field');
  const lastNameContainer = document.querySelector('#billing_last_name_field');
  const companyNameContainer = document.querySelector('#billing_company_field');
  const nipContainer = document.querySelector('#billing_nip_field');

  individual.checked = true;
  companyNameContainer.classList.add('is-hidden');
  nipContainer.classList.add('is-hidden');

  individual.addEventListener('click', e => {
    individual.checked = true;
    business.checked = false;
    firstNameContainer.classList.add('is-visible');
    lastNameContainer.classList.add('is-visible');
    firstNameContainer.classList.remove('is-hidden');
    lastNameContainer.classList.remove('is-hidden');
    companyNameContainer.classList.add('is-hidden');
    companyNameContainer.classList.remove('is-visible');
    nipContainer.classList.add('is-hidden');
    nipContainer.classList.remove('is-visible');
  });

  business.addEventListener('click', e => {
    business.checked = true;
    individual.checked = false;
    companyNameContainer.classList.add('is-visible');
    companyNameContainer.classList.remove('is-hidden');
    nipContainer.classList.add('is-visible');
    nipContainer.classList.remove('is-hidden');
    firstNameContainer.classList.add('is-hidden');
    lastNameContainer.classList.add('is-hidden');
    firstNameContainer.classList.remove('is-visible');
    lastNameContainer.classList.remove('is-visible');
  });
};

export const getScreenWidth = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    width = w.innerWidth || e.clientWidth || g.clientWidth;

  return width;
};

export const getScreenHeight = () => {
  let w = window,
    d = document,
    e = d.documentElement,
    g = d.getElementsByTagName('body')[0],
    height = w.innerHeight || e.clientHeight || g.clientHeight;

  return height;
};

export const isMobile = () => {
  if (getScreenWidth() <= 768) return true;
};

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true;
};

export const getCoords = elem => {
  let box = elem.getBoundingClientRect();

  let body = document.body;
  let docEl = document.documentElement;

  let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  let clientTop = docEl.clientTop || body.clientTop || 0;
  let clientLeft = docEl.clientLeft || body.clientLeft || 0;

  let top = box.top + scrollTop - clientTop;
  let left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};
